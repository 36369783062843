import { Device } from '@capacitor/device';
import { StatusBar } from '@capacitor/status-bar';
import { Deamp } from '@hamstudy/flamp';
import { type FileUpdateEvent, type NewFileEvent, type FileCompleteEvent, File } from '@hamstudy/flamp/dist/deamp';
import { i18next } from '@/locales/i18next';

import formatDate from '@/lib/formatDate';
import router from '@/router';
import { ROUTES } from '@/router/routes';
import { MT63Client, readyDfd } from '@/lib/mt63';
import MT63WorkletPath from 'worklet-loader!@/lib/mt63/mt63.worklet.ts';
import { Transmission, FilenameRegex } from '@/models/transmission';
import { useNotificationStore, type SNACKBAR } from '@/stores/notifications';
import { useRaceEventStore } from '@/stores/raceEvent';
import { useReceiveStore } from '@/stores/receive';
import { useSettingsStore } from '@/stores/settings';
import { importData, importDrops, parseRaceData, parseStationCSV } from './import.service';
import { MT63AudioWorkletNode } from './MT63AudioWorkletNode';

import {
  type AudioInputPluginConfig,
  checkMicrophonePermission,
  getMicrophonePermission,
} from './cordova-plugin-audioinput';
import { storeToRefs } from 'pinia';

const SECONDS = 1000;
let USE_MOBILE_NATIVE_AUDIO = !navigator.mediaDevices && !!(window as any).audioinput;

const audioConfig: AudioInputPluginConfig = {
  channels: 1,
  sampleRate: ((window as any).audioinput && audioinput.SAMPLERATE.TELEPHONE_8000Hz) || 8000,
  bufferSize: 8000,
  format: "PCM_16BIT",
  audioSourceType: ((window as any).audioinput && audioinput.AUDIOSOURCE_TYPE.MIC),
  normalize: true,
  streamToWebAudio: false,
};

const raceEventStore = useRaceEventStore();
const settingsStore = useSettingsStore();
const notificationStore = useNotificationStore();
const receiveStore = useReceiveStore();
const {
  recentBuffer,
  listening,
  hearing,
  recentHash,
} = storeToRefs(receiveStore);

class ReceiveService {
  audioLevel = 0;
  hasMicPerm = false;
  lastReceivedAt: number = 0;
  deamp: Deamp = new Deamp();
  setNotHearing?: NodeJS.Timeout;

  private audioCtx!: AudioContext;
  private audioTrack?: MediaStreamTrack;
  private mtClient: MT63Client = null as any;


  constructor() {
    Promise.all([
      this.initialize(),
      this.initializeDeamp(),
    ]).then(() => {
      if (!settingsStore.POWER_SAVING) {
        this.listen();
      }
    }).catch((e) => {
      console.error(e); // tslint:disable-line no-console
    });
  }

  initializeDeamp() {
    this.deamp.newFileEvent.on(async (evt: NewFileEvent) => {
      recentHash.value = evt.hash;
      const file = this.deamp.getFile(evt.hash);
      notificationStore.snackbar = {
        message: i18next.t('receive.incoming', {hash: evt.hash}),
        timeout: 2 * SECONDS,
      } as SNACKBAR;
      await Transmission.saveTransmission(file, raceEventStore.eventId);
      await receiveStore.updateHistoryList();
    });
    this.deamp.fileUpdateEvent.on(async (evt: FileUpdateEvent) => {
      recentHash.value = evt.hash;
      const file = this.deamp.getFile(evt.hash);
      await Transmission.saveTransmission(file, raceEventStore.eventId);
      await receiveStore.updateHistoryList();
    });
    this.deamp.fileCompleteEvent.on(async (evt: FileCompleteEvent) => {
      const file = this.deamp.getFile(evt.hash);
      await Transmission.saveTransmission(file, raceEventStore.eventId);
      await receiveStore.updateHistoryList();
      let contents: string | undefined = (void 0);
      try {
        contents = file.getContent() || (void 0);
      } catch (e) {
        console.error(e); // tslint:disable-line no-console
      }
      if (settingsStore.autoImport) {
        importFn();
      } else {
        notificationStore.snackbar = {
          action: {
            text: i18next.t(file.name && FilenameRegex.message.test(file.name) ? 'receive.view' : 'receive.importNow'),
            callback: importFn,
          },
          message: i18next.t('receive.historyDetails', {
            0: file.name,
            1: formatDate(new Date(), 'MM/DD HH:mm'),
            interpolation: { escapeValue: false },
          }),
          timeout: 0,
        } as SNACKBAR;
      }
      async function importFn(): Promise<void> {
        try {
          if (file.name && FilenameRegex.message.test(file.name)) {
            router.push({name: ROUTES.viewTransmission.name, query: { routeFrom: router.currentRoute.name }, params: {hash: evt.hash}});
          } else if (file.name && FilenameRegex.entry.test(file.name) && contents) {
            await parseStationCSV(file.name, contents);
          } else if (file.name && FilenameRegex.json.test(file.name) && contents) {
            await importData(contents, file.name, raceEventStore.eventId);
          } else if (file.name && FilenameRegex.drops.test(file.name) && contents) {
            await importDrops(contents);
          } else if (contents) {
            notificationStore.snackbar = { i18next: 'receive.importUnknown' };
            await parseRaceData(contents, file.name, false);
          }
        } catch (e) {
          alert(e);
        }
      }
      this.deamp.popFile(evt.hash);
    });
  }

  async initialize() {
    const [deviceInfo] = await Promise.all([
      Device.getInfo(),
    ]);
    readyDfd.then(() => {
      this.mtClient = new MT63Client();
    });
    if (deviceInfo.platform === 'ios') { USE_MOBILE_NATIVE_AUDIO = true; }

    if (USE_MOBILE_NATIVE_AUDIO) {
      this.hasMicPerm = await checkMicrophonePermission();

      window.addEventListener('audioinput', (evt: any) => this.onAudioInput(evt.data, evt.data.length), false);
      window.addEventListener('audioinputerror', this.onAudioInputError.bind(this) as any, false);

      audioinput.initialize(audioConfig, () => {
        this.audioCtx = audioinput.getAudioContext();
      });
    }
  }

  stopListening() {
    if (USE_MOBILE_NATIVE_AUDIO) {
      audioinput.stop(() => {
        listening.value = false; // This callback isn't being called... why?
      });
      listening.value = false; // Only because the callback isn't being called
    } else if (this.audioTrack) {
      this.audioTrack.stop();
      // No need to set listening to false here because when the stream goes inactive it will do it
      // but we'll set it to false anyway just so we can try to start listening again if something goes wrong
      listening.value = false;
    } else {
      listening.value = false;
    }
  }

  onAudioInput(floatArr: Float32Array, len: number, sampleRate?: number) {
    if (this.lastReceivedAt &&
      (Date.now() - this.lastReceivedAt > 30 * SECONDS) &&
      settingsStore.POWER_SAVING
    ) { this.stopListening(); }

    if (!this.mtClient) {
      try {
        this.mtClient = new MT63Client();
      } catch (e) {
        console.error(e); // tslint:disable-line no-console
        this.stopListening();
        return;
      }
    }

    if (Transmission.activeTransmission?.transmitting) { return; }
    const res = this.mtClient.processAudio(floatArr, len, sampleRate);
    if (res.length) {
      this.onDataReceived(res);
    }
  }

  onDataReceived(res: string) {
    if (Transmission.activeTransmission?.transmitting) { return; }
    console.debug("Received:", res); // tslint:disable-line no-console
    recentBuffer.value += res;
    recentBuffer.value = recentBuffer.value.slice(recentBuffer.value.length - 500);
    this.deamp.ingestString(res);
    this.lastReceivedAt = Date.now();
    if (this.setNotHearing) {
      clearTimeout(this.setNotHearing);
    }
    hearing.value = true;
    this.setNotHearing = setTimeout(() => hearing.value = false, 1 * SECONDS);
  }

  onAudioInputError(evt: ErrorEvent) {
    // TODO: figure out what to do in this case??
    console.error("Couldn't process audio input", JSON.stringify(evt)); // tslint:disable-line no-console
  }

  async listen() {
    recentBuffer.value = '';
    if (listening.value) {
      this.stopListening();
    }
    if (!USE_MOBILE_NATIVE_AUDIO) {
      if (this.audioCtx?.state === 'suspended') {
        await this.audioCtx.resume();
      } else {
        await this.audioCtx?.close();
        this.audioCtx = new ((window as any).AudioContext || (window as any).webkitAudioContext)();
        if (this.audioCtx.state === 'suspended') {
          console.error('Always listening feature is not supported on this device on first load'); // tslint:disable-line no-console
          return;
        }
      }
    }
    this.deamp.clearBuffer();
    this.lastReceivedAt = 0;
    if (USE_MOBILE_NATIVE_AUDIO && !this.hasMicPerm) {
      try {
        // This will throw an exception if it fails
        this.hasMicPerm = await getMicrophonePermission();
      } catch(e) {
        console.error('User has not given permission to access mic', e); // tslint:disable-line no-console
        alert("This app does not have permission to access the micropohone");
        return;
      }
    }

    this.deamp.clearBuffer();
    if (USE_MOBILE_NATIVE_AUDIO) {
      try {
        audioinput.start(audioConfig);
        listening.value = true;
      } catch (e) {
        console.error(e); // tslint:disable-line no-console
        alert(e);
        audioinput.stop();
      }
    } else {
      const isLocalhost = window.location.hostname === 'localhost' ||
        window.location.hostname === '127.0.0.1';
      if (window.location.protocol !== 'https:' && !isLocalhost) {
        alert('HTTPS is required for microphone access, and this site has no SSL cert yet. Sorry!');
      }
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: { exact: false },
          noiseSuppression: { exact: false },
          channelCount: { exact: 1 },
          sampleRate: { ideal: 8000 },
          sampleSize: 16,
        },
      });
      this.audioTrack = stream.getAudioTracks()[0];
      await this.audioCtx.audioWorklet.addModule(MT63WorkletPath);
      const mt63Node = new MT63AudioWorkletNode(this.audioCtx, stream);
      mt63Node.port.addEventListener('message', (e) => {
        if (e.data.decoded) {
          this.onDataReceived(e.data.decoded as string);
          return;
        } else if (e.data.audioBuffer) {
          const audioData = e.data.audioBuffer as Float32Array;
          const sampleRate = e.data.sampleRate as number;
          // console.log("Rcvd -- Sample rate:", sampleRate, " and max: ", audioData.reduce((memo, cur) => Math.max(memo, cur), 0));
          this.onAudioInput(audioData, audioData.length, sampleRate);
          // let res = this.mtClient.processAudio(audioData, audioData.length, sampleRate);
        }
      });
      stream.addEventListener('inactive', () => {
        listening.value = false;
        mt63Node.port.postMessage({req: 'shutdown'});
      }, {once: true});
      listening.value = true;
    }
  }
}

// Question where would you handle onDestroy?
export default new ReceiveService();
