import { defineStore, storeToRefs } from 'pinia';
import { ref } from 'vue';
import Participant from '@/models/participant';
import Transmission from '@/models/transmission';
import { useRaceEventStore } from '@/stores/raceEvent';
import { useSettingsStore } from '@/stores/settings';

export const useTransmissionStore = defineStore('transmission', () => {
  const raceEventStore = useRaceEventStore();
  const {
    callsign: fromCallsign,
  } = storeToRefs(useSettingsStore());
  const state = {
    history: ref<Transmission[]>([]),
    transmission: ref<Transmission | null>(null),

    async updateHistoryList() {
      const eventId = raceEventStore.eventId;
      const history = await Transmission.getTxDocs(eventId);
      history.sort((x,y) => x.timestamp > y.timestamp ? -1 : 1);
      state.history.value = history;
    },

    async transmitDnfList() {
      const eventId = raceEventStore.eventId;
      const participants = (await Participant.getParticipants(eventId)).filter((p) => p.dnfStation || p.dnfStation === 0);
      const dnfList = participants
        .map((p) => ([p.bibNumber, p.dnfStation, p.dnfReason].join(',')))
      ;
      const filename = `drops-${raceEventStore.eventSlug || raceEventStore.eventId}.txt`;
      const contents = dnfList.join('\n');
      const highestFileModifiedTime = Math.max(...participants.map(p => p.modified?.getTime() || 0));
      const fileModifiedTimeDate = highestFileModifiedTime ? new Date(highestFileModifiedTime) : undefined;
      // const fileModifiedTimeDate = new Date(1739344274521);
      // @TODO I'm not sure if fileModifiedTimeDate is being set correctly
      // Also I'm not sure if modified time is best for this... probably though
      const fetchedTransmission = Transmission.fromString(filename, contents, eventId, fromCallsign.value, fileModifiedTimeDate);
      fetchedTransmission.transmit((void 0), fromCallsign.value);
      await fetchedTransmission.save();
    },
  };

  const origTransmit = Transmission.prototype.transmit;
  Transmission.prototype.transmit = async function transmit(blocksToTransmit?: {[key: number]: boolean, headers: boolean}, fromCallsign?: string, toCallsign?: string) {
    await origTransmit.call(this, blocksToTransmit, fromCallsign, toCallsign);
    state.transmission.value = this;
  };

  return state;
});
