import { IStationDoc } from '@/models/station';
import { type IEntryDoc } from './entry';

export const dbName = <const>'participants';

export type ImportParticipant = Partial<IParticipantDoc> & { bibNumber: number; entrys?: {[stationNumber: string]: Partial<IEntryDoc>}; raceName?: string; };

// tslint:disable max-classes-per-file
abstract class ParticipantDoc {
  id!: string;
  age?: string;
  bibNumber!: number;
  firstName?: string;
  lastName?: string;
  modified?: Date;
  note?: string;
  home?: string;
  eventId!: string;
  raceId?: string;
  sex?: 'M' | 'F';
  team?: string;
  dnfReason = '';
  dnfStation: number | null = null;
}
export type IParticipantDoc = ParticipantDoc;
export class Participant extends ParticipantDoc {
  static async getParticipant(raceEventId: string, bibNumber: number): Promise<Participant | null> { throw new Error("Can't use base model"); }
  static async getParticipants(raceEventId: string, ids?: string[]): Promise<Participant[]> { throw new Error("Can't use base model"); }
  static async delete(participant: Participant) { throw new Error("Can't use base model"); }

  static async importParticipant(eventId: string, participantData: ImportParticipant) {
    const runner = (await this.getParticipant(eventId, participantData.bibNumber)) || new this(eventId, {bibNumber: participantData.bibNumber});
    runner.age = participantData.age || runner.age;
    runner.dnfReason = participantData.dnfReason || runner.dnfReason;
    if (typeof participantData.dnfStation === 'number') {
      runner.dnfStation = participantData.dnfStation;
    }
    runner.firstName = participantData.firstName || runner.firstName;
    runner.lastName = participantData.lastName || runner.lastName;
    runner.home = participantData.home || runner.home;
    runner.note = participantData.note || runner.note;
    runner.raceId = participantData.raceId || runner.raceId;
    runner.sex = participantData.sex || runner.sex;
    runner.team = participantData.team || runner.team;
    try {
      await runner.save();
    } catch (e) {
      console.error(e); // tslint:disable-line no-console
      throw e;
    }
    return runner;
  }

  constructor(
    raceEventId: string,
    obj: Partial<Participant> & Pick<Participant, 'bibNumber'>,
  ) {
    super();
    Object.assign(this, obj, {eventId: raceEventId});

    if (isNaN(Number(this.bibNumber))) { throw new Error('Tried to create a runner, but runner obj is missing valid bibNumber'); }
    return this;
  }
  async save(): Promise<void> { return; }

  drop({reason, station}: {reason: string, station: IStationDoc}) {
    this.dnfStation = station.stationNumber;
    this.dnfReason = reason;
  }
  undrop() {
    this.dnfStation = null;
    this.dnfReason = '';
  }
}
export default Participant;
