import { StatusBar } from '@capacitor/status-bar';
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import Transmission from '@/models/transmission';
import receiveService from '@/services/receive.service';
import { useRaceEventStore } from '@/stores/raceEvent';
import vuetify from '@/plugins/vuetify';

export const useReceiveStore = defineStore('receive', () => {
  const raceEventStore = useRaceEventStore();
  const state = {
    hearing: ref(false),
    listening: ref(false),
    recentHash: ref(''),
    recentBuffer: ref<string>(''),
    history: ref<Transmission[]>([]),
    transmission: ref<Transmission | null>(null),
    recentTransmission: computed<Transmission | undefined>((): Transmission | undefined => {
      const transmission: Transmission | undefined = state.history.value.find((x: Transmission) => x.hash === state.recentHash.value);
      return transmission;
    }),

    listen() {
      receiveService.listen();
    },
    stopListening() {
      receiveService.stopListening();
    },
    async updateHistoryList() {
      const eventId = raceEventStore.eventId;
      const history = await Transmission.getRxDocs(eventId);
      history.sort((x,y) => x.timestamp > y.timestamp ? -1 : 1);
      state.history.value = history;
    },
  };
  watch(state.listening, async (listening) => {
    if (!StatusBar) {
      return;
    }
    try {
      const color = listening ? '#FF0000' : vuetify.framework.theme.currentTheme.primary as string;
      console.log('Setting status bar color to', color); // tslint:disable-line no-console
      await StatusBar.setBackgroundColor({ color });
    } catch (e) {
      if (!String(e).includes(`plugin is not implemented on web`)) {
        console.error(e); // tslint:disable-line no-console
      }
    }
  }, { immediate: true });
  return state;
});
