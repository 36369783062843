import type { StationModel, StoreNames, StoreType } from ".";
import { idbModel } from "./idbModel";

export interface RaceSchema {
  id: string;
  eventId: string;
  name: string;
  distance?: number;
  stations: Array<{
    id: string;
  }>;
}

export class RaceModel extends idbModel<RaceSchema> {
  static readonly StoreName = 'races';
  static readonly StoreSchema = {
    key: RaceModel.StoreName,
    value: null as any as RaceSchema,
    indexes: {
      'eventId': 'eventId',
      'event-name': ['eventId', 'name'],
    },
    keyPath: 'id',
    indexOptions: {
      'event-name': { unique: true },
    },
  };

  static async findById<T extends StoreType<StoreNames> = RaceSchema>(id: string): Promise<T | undefined> {
    return await super.findById<T>(id);
  }

  static async getForEvent(eventId: string): Promise<RaceSchema[]> {
    const store = await this.getObjectStore<'races'>("readonly");

    const range = IDBKeyRange.only(eventId);

    const outDocs: RaceSchema[] = [];

    const iterate = store.index('eventId').iterate(range);
    for await (const doc of iterate) {
      outDocs.push(doc.value);
    }
    return outDocs;
  }


  static async getByEventAndName(eventId: string, name: string): Promise<RaceSchema | undefined> {
    const store = await this.getObjectStore<'races'>("readonly");

    const range = IDBKeyRange.only([eventId, name]);

    return await store.index('event-name').get(range);
  }

  static async deleteAllForEvent(eventId: string): Promise<void> {
    const store = await this.getObjectStore<'races'>('readwrite', 'strict');

    const range = IDBKeyRange.only(eventId);

    const operations: Array<Promise<any>> = [];
    for await (const r of store.index('eventId').iterate(range)) {
      operations.push(r.delete!());
    }
    await Promise.all([
      ...operations,
      store.transaction.done,
    ]);
  }
}
