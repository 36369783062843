import { render, staticRenderFns } from "./Main.vue?vue&type=template&id=4ae2b383&scoped=true"
import script from "./Main.vue?vue&type=script&setup=true&lang=ts"
export * from "./Main.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Main.vue?vue&type=style&index=0&id=4ae2b383&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ae2b383",
  null
  
)

export default component.exports