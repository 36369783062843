/* use require() instead of import so it only actually imports the code that is used */
import { Amp, CompressionType, BaseEncode } from '@hamstudy/flamp/dist/amp';
import TransmissionBase, { type ITransmissionDoc } from './base/transmission';
import { Station } from './station';
import { Participant } from './participant';
import { Entry } from './entry';

let Transmission!: typeof TransmissionBase;
if (__USE_INDEXEDDB__) {
  Transmission = require('./indexeddb/transmission').default; // tslint:disable-line no-var-requires
} else if (__USE_AJAX__) {
  Transmission = require('./indexeddb/transmission').default; // tslint:disable-line no-var-requires
}
type Transmission = TransmissionBase;

const FilenameRegex = {
  drops: /^drops/i,
  entry: /^station-\d+/i,
  event: /^race/i,
  json: /\.json$/i,
  message: /^message/i,
};
Transmission.fromAmp = function (eventId: string, amp: Amp, txOrRx: 'tx' | 'rx') { // tslint:disable-line only-arrow-functions
  if (FilenameRegex.entry.test((amp as any).filename)) {
    const participantCount = amp.inputBuffer.split('\n').length - 1;
  }
  return new Transmission(eventId, {
    hash: amp.hash,
    blocks: amp.blocks,
    txOrRx,
  });
};
const NonASCII = /[^\x00-\x7F]/g;
Transmission.fromString = function (filename: string, inputBuffer: string, eventId: string, fromCallsign?: string, fileModifiedTime?: Date) { // tslint:disable-line only-arrow-functions
  inputBuffer = inputBuffer.normalize('NFKD').replace(NonASCII, '');
  const amp = new Amp({
    fromCallsign: fromCallsign || (void 0),
    filename,
    fileModifiedTime: fileModifiedTime || new Date(),
    inputBuffer,
    blkSize: 64,
    compression: CompressionType.LZMA,
    base: BaseEncode.b64,
    skipProgram: true,
    useEOF: false,
    useEOT: false,
  });
  return new Transmission(eventId, {hash: amp.hash, txOrRx: 'tx', blocks: amp.blocks});
};

function getFilename(stationNumber: number) {
  return `Station-${stationNumber}.txt`;
}
function runnersToFileStr(participants: Participant[], entrys: Entry[], station: Station) {
  const participantMap = participants.reduce((m, p) => { m[p.id] = p; return m; }, {} as {[participantId: string]: Participant});
  const includeSecondsWithTime = entrys.some(e => e.timeIn?.getSeconds() || e.timeOut?.getSeconds());
  let epoch = entrys.reduce((t, e) => Math.min(e.timeIn?.getTime() || Infinity, e.timeOut?.getTime() || Infinity, t), Infinity);
  epoch = Math.floor(epoch / (includeSecondsWithTime ? 6E2 : 6E4));
  let resp = '';
  const metadata = {
    epoch,
  };
  resp += JSON.stringify(metadata) + '\n';
  resp += entrys.reduce((str, e) => {
    const participant = participantMap[e.participantId];
    str += `${participant.bibNumber},`;
    let timeIn = e.timeIn?.getTime();
    timeIn = timeIn && Math.floor(timeIn / (includeSecondsWithTime ? 6E2 : 6E4));
    let timeOut = e.timeOut?.getTime();
    timeOut = timeOut && Math.floor(timeOut / (includeSecondsWithTime ? 6E2 : 6E4));
    str += timeIn ? `${Math.floor(timeIn - epoch)},` : ',';
    str += timeOut ? `${Math.floor(timeOut - epoch)}` : '';
    if (typeof participant.dnfStation === 'number' && participant.dnfStation === station.stationNumber) {
      str += `,${participant.dnfReason}`;
    }
    str += '\n';
    return str;
  }, '');
  return resp;
}

export type {
  ITransmissionDoc,
};
export {
  Transmission,
  FilenameRegex,
  getFilename,
  runnersToFileStr,
};
export default Transmission;

if (isDev) {
  (window as any).Transmission = Transmission;
}
